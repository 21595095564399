// Production configs
console.log('Production configs');

export default {
  apiKey: 'AIzaSyD0aOiUj2EJP_I5Zuhky19-eVjJnZZph7I',
  authDomain: 'braggawatt-production.firebaseapp.com',
  databaseURL: 'https://braggawatt-production.firebaseio.com',
  projectId: 'braggawatt-production',
  storageBucket: 'braggawatt-production.appspot.com',
  messagingSenderId: '966081066481'
};

/*
export default {
	apiKey: "AIzaSyBGPCJKQZJRF-lynv1rGBftSAiZcRmiHwY",
	authDomain: "braggawatt-io.firebaseapp.com",
	databaseURL: "https://braggawatt-io.firebaseio.com",
	storageBucket: "braggawatt-io.appspot.com",
	messagingSenderId: "331282190188"
}
*/
